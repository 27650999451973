<template>
  <b-row class="page-vault">
    <b-col>
      <PageHeader :title="$t('general.vault.title')">
        <button
          type="button"
          @click="newMedia"
          class="base-button post-create__button post-create__button--header"
        >
          <span class="base-button__font">
            {{ $t("general.vault.addNew") }}
          </span>
        </button>
      </PageHeader>

      <div class="page-vault__list">
        <InfinityScroll
          v-if="vaultList && vaultList.length"
          :items="vaultList"
          :has-more="Boolean(vault.next_page_url)"
          @rich-end="loadMore"
          is-grid
        >
          <template #default="{ item: card }">
            <PostTile
              :post="card"
              inactive
              is-vault
              :selectable="isSelectable"
              @select="onSelect"
              @unselect="onUnselect"
            />
          </template>
        </InfinityScroll>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";

import PageHeader from "@/layout/PageHeader.vue";

import {mapActions, mapGetters, mapMutations} from "vuex";
import InfinityScroll from "@/features/components/InfinityScroll.vue";
import PostTile from "@/features/containers/profile/PostTile.vue";
export default {
  name: "PageVaultSelect",
  components: {
    PostTile,
    InfinityScroll,
    PageHeader,
  },
  mixins: [waitRequest, validationError],
  computed: {
    ...mapGetters({
      user: "user",
      vault: "media/vault",
    }),
    vaultList() {
      return this.vault?.data || [];
    },
  },
  data() {
    return {
      selectedPost: [],
      isSelectable: true,
    };
  },
  mounted() {
    this.initState();
  },
  methods: {
    ...mapActions({
      fetchVault: "media/fetchVault",
    }),
    ...mapMutations({
      setSelectedMedia: "media/setSelectedMedia",
    }),

    initState() {
      this.fetchVault().catch(this.checkErrors);
    },

    newMedia() {
      this.$popup.open("AddMediaPopup");
    },

    createQueryConfig(config) {
      return {
        page: this.vault.current_page || 1,
        limit: this.vault.per_page || 30,

        ...config,
      };
    },

    loadMore() {
      const queryConfig = this.createQueryConfig({
        page: this.vault.current_page + 1,
      });
      return this.waitRequest(() => {
        return this.fetchVault(queryConfig).catch(this.checkErrors);
      });
    },
    onSelect(post) {
      this.selectedPost.push(post);
      this.setSelectedMedia(this.selectedPost)
      console.log("onselect", this.selectedPost);
    },
    onUnselect(post) {
      this.selectedPost = this.selectedPost.filter(
        (item) => item.id !== post.id
      );
      this.setSelectedMedia(this.selectedPost)
      console.log("un select", this.selectedPost);
    },
  },
};
</script>

<style scoped lang="scss">
.page-vault {
  &__col {
    padding-right: 0;
    padding-left: 0;
  }

  &__list {
    margin-right: -15px;
    margin-left: -15px;
  }
}

.base-button {
  $parent: &;

  display: inline-flex;
  align-items: center;

  border-radius: em(100, 24);
  border: 1px solid $app-blue;
  background-color: $app-blue;
  padding: em(7, 24) em(15, 24);
  color: $white;

  &__font {
    color: $white;
    text-align: center;
    font-family: $font-default;
    font-size: em(12, 16);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
</style>
